import { Routes, Route } from 'react-router-dom';
import awsLogo from './assets/images/aws.svg';
import beatles from './assets/images/beatles.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={awsLogo} className="App-logo" alt="logo" />
        <p>
          Jobchart.nl
        </p>
      </header>
      <section>
        <Routes>
          <Route
            path="/beatles"
            element={
              <div>
                <img
                  src={beatles}
                  alt="Possibly the Beatles, possibly"
                />
              </div>
            }
          />
          <Route path="/" element={<p>Welcome. Comewel. Mewelco.</p>} />
        </Routes>
      </section>
    </div>
  );
}

export default App;
